import React from 'react'
import Email from 'components/Email'

const Contact = () => {
  return (
    <>
      <Email title="CONTACT" description="Get in touch" />
    </>
  )
}

export default Contact
